import React, { useEffect } from 'react';
import Layout from '../components/Layout';
import RonakHero from '../components/RonakPipaliya/RonakHero';

import allApiData from "../api";
import PortfolioCom from '../components/Home/PortfolioCom';
import allData from '../allData';
import IndustriesCom from '../components/Home/IndustriesCom';

const ronakExpertisesData = allData.ronakExpertisesData;

const userData = {
    name: "Ronak Pipaliya",
    designation: "Project Manager",
    image: '../assets/img/ronak-pipaliya/ronak-pipaliya.png',
    tag: `ronak-pipaliya`,
    phones: ['(+91) 8000141985', '(+91) 7359349940'],
    // emails: ['ronak@vasundhara.io', 'ronak.vasundhara@gmail.com'],
    emails: ['ronak@vasundhara.io'],
    linkedin: 'https://in.linkedin.com/in/ronak-pipaliya-4839031a9',
    // facebook='https://www.facebook.com/ronak.pipaliya.92',
    skype: 'https://join.skype.com/invite/ThIk9HM34qQg',
    behance: `https://www.behance.net/vasundharainfotech`,
    whatsapp: `https://wa.me/+918000141985`,
    description: [
        `Mr. Ronak Pipaliya, a highly experienced professional within Vasundhara Infotech, demonstrates outstanding proficiency as the Head of the Game Department. `,
        `He is committed to the mission of crafting engaging and interactive game experiences, consistently setting high standards for user-friendly interfaces and visually appealing designs.`,
        `He is proficient in the gaming industry's cutting-edge technology and provides valuable assistance to his team in optimizing code and performance for an exceptional gaming experience.`,
        `He has a strategic vision of creating interactive games with captivating user interfaces, setting a high standard for user engagement and enjoyment.`,
        `He leverages a profound understanding of business operations to positively impact the success of company projects, consistently aligning them with organizational goals.`,
        `With a vigilant eye on the web development process, he conducts regular checks and assists the team in troubleshooting any issues encountered during the development and testing phases.`,
        `Renowned for his ability to orchestrate and lead multifunctional teams, he consistently delivers on customer objectives and drives excellence in the world of gaming.`,

    ]
}

function RonakPipaliya() {

    const tabNameData = ['Game Development'];
        useEffect(() => {
            allApiData.aosinit();
    }, []);

    return (
        <Layout footernone={false} padding={true}>
            <div className='main-ronak position-relative'>
                <div className='ronak-hero'>
                    <RonakHero userData={userData} />
                </div>

                <div className="ronak-portfolio">
                    <PortfolioCom
                        heading="Wall of Pride"
                        isTabOn={true}
                        isImageComp={true}
                        isViewBtn={true}
                        // viewurl={`portfolio?type=game_development`}
                        viewurl={`portfolio`}
                        viewButton={'View the entire portfolio'}
                        tabData={tabNameData}
                        typeWise='app_type'
                        isPagination={false}
                        activeTab='Game Development'
                        //portfolios={portFolio}
                    />
                </div>

                <div className="ronak-expertises">
                    <IndustriesCom h1="Expertises" industriesData={ronakExpertisesData} buttonOn={false} bg={true} upperCase={true} />
                </div>

            </div>
        </Layout>
    )
}

export default RonakPipaliya